import React, { useState } from 'react';

import Card from '../components/Card';
import CardList from '../components/CardList';
import HeaderMargin from '../components/HeaderMargin';
import Layout from '../components/layout';
import Loader from '../components/Loader';
import QuoteBlock from '../components/QuoteBlock';
import SEO from '../components/seo';

import { Intro } from '../content/library';

export default ({ data }) => {
  const posts = data.allWordpressPost.edges;
  return (
    <Layout>
      <SEO title="Library" />
      <HeaderMargin />
      <QuoteBlock
        grayText={"Sometimes all it takes is a little"}
        redText={"inspiration"}
        center
        room
      >
        <Intro />
      </QuoteBlock>

      <CardList>
        { posts.map((node, index) => (
          node.node.categories.includes(2136)
            ? null
            : <Card {...node} index={index} />
        )) }
      </CardList>
    </Layout>
  )
};

export const query = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          categories
          content
          excerpt
          id
          jetpack_featured_media_url
          title
          slug
        }
      }
    }
  }
`;
