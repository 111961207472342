import React from 'react';

export const Intro = () => (
  <>
    If you’re feeling curious and interested in exploring specific areas of your life, this is the place for you. If you’re anything like us, sometimes it is really helpful to read stories about other people in order to reflect on where we are in our lives, and where we want to be. 
    <br />
    <br />
    Here you will find a series of blogs and articles that delve into each of the six parts of founder Dan Burrell’s Wheel of Purpose—relationships, physical health, professional life, play, emotional health, and spirituality. There are tidbits of wisdom ranging from how to nail a job interview, some excellent communication advice, and some fascinating reflections on the function of fear and how it prevents action in daily life. Grab some coffee, get comfortable, and dive in.
  </>
)
